import React from 'react'
import Image from 'react-bootstrap/Image'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLine,
  faLinkedin,
  // faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'
import {
  userSolid,
  userImgDefault,
  userImgWorker,
  userImgView,
  userImgInfoPro,
  userImgAnaly,
  userImgFina,
  userImgAdmin,
  userImgVIP,
  userImgGhost,
  userImgDev,
  userImgWorkerSm,
  userImgViewSm,
  userImgInfoProSm,
  userImgAnalySm,
  userImgFinaSm,
  userImgAdminSm,
  userImgVIPSm,
  userImgGhostSm,
  userImgDevSm,
  // accTypeFB,
  accTypeGoogle,
  accTypeTTD,
  accTypeXTwitter,
  // accTypeYahoo,
  accTypeYahoo,
  accTypeYahooMA,
  accTypeCriteo,
  ErrorIcon,
  accTypeMeta,
  accTypeTaboola,
  accDcard,
  accTypeTiktok,
} from '../assets'
import fields from '../configure/fields.json'
// import apiService from './apiService'

const getCookie = (cname) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      const cookie = c.substring(name.length, c.length)
      if (cookie === '') return false
      return cookie
    }
  }
  return false
}

const platforms = [
  { name: 'Facebook', value: 'Facebook' },
  { name: 'Google', value: 'Google' },
  { name: 'Twitter', value: 'Twitter' },
  { name: 'LinkedIn', value: 'LinkedIn' },
  { name: 'YahooDSP', value: 'YahooDSP' },
  { name: 'Taboola', value: 'Taboola' },
  { name: 'YahooMA', value: 'YahooMA' },
  { name: 'Line', value: 'Line' },
  { name: 'TTD', value: 'TTD' },
  { name: 'Criteo', value: 'Criteo' },
  { name: 'Dcard', value: 'Dcard' },
  { name: 'Tiktok', value: 'Tiktok' },
]

const departs = [
  {
    name: '執行長室',
    value: '執行長室',
  },
  {
    name: '行銷業務一部',
    value: '行銷業務一部',
  },
  {
    name: '行銷業務二部',
    value: '行銷業務二部',
  },
  {
    name: '整合行銷一部',
    value: '整合行銷一部',
  },
  {
    name: '整合行銷二部',
    value: '整合行銷二部',
  },
  {
    name: '整合行銷三部',
    value: '整合行銷三部',
  },
  {
    name: '整合行銷四部',
    value: '整合行銷四部',
  },
  {
    name: '解決方案部',
    value: '解決方案部',
  },
  {
    name: '財務部',
    value: '財務部',
  },
  {
    name: '稽核室',
    value: '稽核室',
  },
  {
    name: '人資行政部',
    value: '人資行政部',
  },
  {
    name: '發布商夥伴部',
    value: '發布商夥伴部',
  },
  {
    name: '市場部',
    value: '市場部',
  },
  {
    name: '程序化廣告及數據洞察部',
    value: '程序化廣告及數據洞察部',
  },
  {
    name: '電商部',
    value: '電商部',
  },
  {
    name: '數位轉型中心一部',
    value: '數位轉型中心一部',
  },
  {
    name: '電商整合服務部',
    value: '電商整合服務部',
  },
]

const getTimeRanges = () => [
  { label: 'Today', timeRange: { since: moment(), until: moment() } },
  // { label: 'Yesterday', since: 1, until: 1 },
  {
    label: 'Last 3 Days',
    timeRange: {
      since: moment().subtract(3, 'days'),
      until: moment(),
    },
  },
  {
    label: 'Last 5 Days',
    timeRange: {
      since: moment().subtract(5, 'days'),
      until: moment(),
    },
  },
  {
    label: 'This Week',
    timeRange: { since: moment().startOf('week'), until: moment() },
  },
  // { label: 'Last 14 Days', since: 13, until: 0 },
  // { label: 'Last 28 Days', since: 27, until: 0 },
  {
    label: 'This Month',
    timeRange: { since: moment().startOf('month'), until: moment() },
  },
  {
    label: 'This Quarter',
    timeRange: {
      since: moment().startOf('quarter'),
      until: moment(),
    },
  },
  {
    label: 'This Year',
    timeRange: { since: moment().startOf('year'), until: moment() },
  },
]

const getAvatar = ({ permission = {}, avatarId }) => {
  if (!avatarId) {
    if (permission.isAdmin) return userImgAdmin
    if (permission.isAdsManager) return userImgAnaly
    if (permission.isAnalyst) return userImgView
    if (permission.isFinancial) return userImgFina
    return userImgDefault
  }
  switch (parseInt(avatarId, 10)) {
    case 2:
      return userImgWorker
    case 3:
      return userImgView
    case 4:
      return userImgInfoPro
    case 5:
      return userImgAnaly
    case 6:
      return userImgFina
    case 7:
      return userImgAdmin
    case 8:
      return userImgVIP
    case 9:
      return userImgDev
    case 10:
      return userImgGhost
    default:
      return userImgDefault
  }
}

const getAvatarSm = ({ permission = {}, avatarId }) => {
  if (!avatarId) {
    if (permission.isAdmin) return userImgAdminSm
    if (permission.isAdsManager) return userImgAnalySm
    if (permission.isAnalyst) return userImgViewSm
    if (permission.isFinancial) return userImgFinaSm
    return userSolid
  }
  switch (parseInt(avatarId, 10)) {
    case 2:
      return userImgWorkerSm
    case 3:
      return userImgViewSm
    case 4:
      return userImgInfoProSm
    case 5:
      return userImgAnalySm
    case 6:
      return userImgFinaSm
    case 7:
      return userImgAdminSm
    case 8:
      return userImgVIPSm
    case 9:
      return userImgDevSm
    case 10:
      return userImgGhostSm
    default:
      return userSolid
  }
}

const getPermissionType = (account = { permission: {} }) => {
  if (!account || !account.permission) return '訪客'
  const { permission } = account
  if (permission.isAdmin) return 'ADM管理者'
  if (permission.isAdsManager) return '投放優化師'
  if (permission.isAnalyst) return '成效分析師'
  if (permission.isFinancial) return '財務人員'
  return '訪客'
}

const getStaticPath = (name) => `/api/file/${name}`

const wordSearch = (word, search) =>
  word && word.toLowerCase().includes(search.toLowerCase())

const getLanguauage = (str) => {
  const cpattren = '[\u4E00-\u9FA5]+'
  const epattren = '[A-Za-z]+'
  const npattren = '[0-9]+'
  if (new RegExp(cpattren).test(str)) return 'chinese'
  if (new RegExp(epattren).test(str)) return 'english'
  if (new RegExp(npattren).test(str)) return 'number'
  return 'none'
}

const icons = {
  meta: { src: accTypeMeta, type: 'image' },
  facebook: { src: accTypeMeta, type: 'image' },
  google: { src: accTypeGoogle, type: 'image' },
  twitter: { src: accTypeXTwitter, type: 'image', color: '#1b85cc' },
  x: { src: accTypeXTwitter, type: 'image', color: '#1b85cc' },
  linkedin: { src: faLinkedin, type: 'fa', color: '#1477af' },
  line: { src: faLine, type: 'fa', color: '#319949' },
  ttd: { src: accTypeTTD, type: 'image' },
  yahooma: { src: accTypeYahooMA, type: 'image' },
  yahoo關鍵字: { src: accTypeYahooMA, type: 'image' },
  yahoodsp: { src: accTypeYahoo, type: 'image' },
  'yahoo native': { src: accTypeYahoo, type: 'image' },
  taboola原生廣告: { src: accTypeTaboola, type: 'image' },
  taboola: { src: accTypeTaboola, type: 'image' },
  criteo: { src: accTypeCriteo, type: 'image' },
  dcard: { src: accDcard, type: 'image' },
  tiktok: { src: accTypeTiktok, type: 'image' },
  'tik tok': { src: accTypeTiktok, type: 'image' },
}

const getIcon = (Platform, height, width, opacity, className = 'ms-4') => {
  const platform = Platform ? Platform.toLowerCase() : 'meta'
  return (
    <>
      {icons[platform] && icons[platform].type === 'image' && (
        <Image
          height={height}
          // width={width}
          className={`${className} pe-2 align-self-center`}
          style={{
            opacity,
          }}
          src={icons[platform].src || ErrorIcon}
          title={icons[platform] ? platform : '分 類 失 效'}
        />
      )}
      {icons[platform] && icons[platform].type === 'fa' && (
        <FontAwesomeIcon
          className={`${className} ms-4 pe-2 align-self-center`}
          style={{
            height,
            color: icons[platform].color,
            opacity,
          }}
          icon={icons[platform].src || ErrorIcon}
          title={icons[platform] ? platform : '分 類 失 效'}
        />
      )}
    </>
  )
}

const getOriginIcon = (Platform, height, width, opacity, className) => {
  const platform = Platform.toLowerCase()
  return (
    <>
      {icons[platform] && icons[platform].type === 'image' && (
        <Image
          height={height}
          width={width}
          className={`${className}`}
          style={{
            opacity,
          }}
          src={icons[platform].src || ErrorIcon}
          title={icons[platform] ? platform : '分 類 失 效'}
        />
      )}
      {icons[platform] && icons[platform].type === 'fa' && (
        <FontAwesomeIcon
          className={`${className}`}
          style={{
            height,
            width,
            color: icons[platform].color,
            opacity,
          }}
          icon={icons[platform].src || ErrorIcon}
          title={icons[platform] ? platform : '分 類 失 效'}
        />
      )}
    </>
  )
}

const getFixedForm = (auth, formData = {}) =>
  auth.account.type === 'BM'
    ? [
        {
          name: '填表人',
          label: '填表人',
          placeholder: '...',
          type: 'fixed',
          value: formData['填表人'] || auth.auth.name,
        },
        {
          name: '需求單位',
          label: '需求單位',
          placeholder: '...',
          type: 'fixed',
          value: formData['需求單位'] || auth.account.name,
        },
        {
          name: '填表人所屬單位',
          label: '填表人所屬單位',
          placeholder: '...',
          type: 'fixed',
          value:
            formData['填表人所屬單位'] ||
            auth.auth.setting.depart ||
            auth.account.name,
        },
        {
          name: '客戶類型',
          label: '客戶類型',
          placeholder: '...',
          type: 'fixed',
          value: '代理商',
        },
        {
          name: '潮網銷售顧問',
          label: '潮網銷售顧問',
          placeholder: '...',
          type: 'fixed',
          value:
            formData['潮網銷售顧問'] ||
            (auth.account.setting &&
              auth.account.setting['潮網銷售顧問*ERP業務專員']) ||
            `${auth.account.name} 的顧問`,
        },
        {
          name: '代理商公司工商登記名稱',
          label: '代理商公司工商登記名稱',
          placeholder: '...',
          type: 'fixed',
          value: formData['代理商公司工商登記名稱'] || auth.account.name,
        },
      ]
    : [
        {
          name: '填表人',
          label: '填表人',
          placeholder: '...',
          type: 'fixed',
          value: formData['填表人'] || auth.auth.name,
        },
        {
          name: '潮網銷售顧問',
          label: '潮網銷售顧問',
          placeholder: '...',
          type: 'fixed',
          value:
            formData['潮網銷售顧問'] ||
            (auth.account.setting &&
              auth.account.setting['潮網銷售顧問*ERP業務專員']) ||
            `${auth.account.name} 的顧問`,
        },
        {
          name: '客戶類型',
          label: '客戶類型',
          placeholder: '...',
          type: 'fixed',
          value: '直客',
        },
      ]

const getForm = (type, platform, managed) => {
  // select datas
  const ttdCampanyTypes = [
    { name: 'Art & Entertainment', value: 'Art & Entertainment' },
    { name: 'Automotive', value: 'Automotive' },
    { name: 'Business', value: 'Business' },
    { name: 'Careers', value: 'Careers' },
    { name: 'Education', value: 'Education' },
    { name: 'Family & Parenting', value: 'Family & Parenting' },
    { name: 'Health & Fitness', value: 'Health & Fitness' },
    { name: 'Food & Drink', value: 'Food & Drink' },
    { name: 'Hobbies & Interests', value: 'Hobbies & Interests' },
    { name: 'Home & Garden', value: 'Home & Garden' },
    { name: `Law,Gov't & Politics`, value: `Law,Gov't & Politics` },
    { name: 'News', value: 'News' },
    { name: 'Personal Finance', value: 'Personal Finance' },
    { name: 'Society', value: 'Society' },
    { name: 'Science', value: 'Science' },
    { name: 'Pets', value: 'Pets' },
    { name: 'Sports', value: 'Sports' },
    { name: 'Style & Fashion', value: 'Style & Fashion' },
    { name: 'Technology & Computing', value: 'Technology & Computing' },
    { name: 'Travel', value: 'Travel' },
    { name: 'Real Estate', value: 'Real Estate' },
    { name: 'Shopping', value: 'Shopping' },
    { name: 'Religion & Spirituality', value: 'Religion & Spirituality' },
  ]

  const ttdTypes = [
    { name: '3rd Party Rotating Tags', value: '3rd Party Rotating Tags' },
    {
      name: 'Ad-Supported Downloadable Application',
      value: 'Ad-Supported Downloadable Application',
    },
    { name: 'Adult', value: 'Adult' },
    { name: 'Alcohol,Liquor,and Spirits', value: 'Alcohol,Liquor,and Spirits' },
    {
      name: 'Automotive & Auto-Related Peripherals and Services',
      value: 'Automotive & Auto-Related Peripherals and Services',
    },
    { name: 'Beaty & Hygiene', value: 'Beaty & Hygiene' },
    { name: 'Blogging software', value: 'Blogging software' },
    { name: 'Business', value: 'Business' },
    {
      name: 'Computer Hardware(Not Software)',
      value: 'Computer Hardware(Not Software)',
    },
    { name: 'Cosmetic Medical Services', value: 'Cosmetic Medical Services' },
    { name: 'Credit Card Offers', value: 'Credit Card Offers' },
    {
      name: 'Culture & Leisure Activities',
      value: 'Culture & Leisure Activities',
    },
    { name: 'Currency Trading', value: 'Currency Trading' },
    { name: 'Dating', value: 'Dating' },
    { name: 'Downloads', value: 'Downloads' },
    { name: 'Drug-Prescription/OTC', value: 'Drug-Prescription/OTC' },
    { name: 'Education', value: 'Education' },
    {
      name: 'Electronic Retailers and Manufacturers',
      value: 'Electronic Retailers and Manufacturers',
    },
    { name: 'Employment', value: 'Employment' },
    { name: 'Energy', value: 'Energy' },
    { name: 'Entertainment', value: 'Entertainment' },
    { name: 'Finance', value: 'Finance' },
    { name: 'Fitness', value: 'Fitness' },
    { name: 'Food & Beverage', value: 'Food & Beverage' },
    {
      name: 'Gambling(Bricks-and-Mortar Casino or Hotel Casino)',
      value: 'Gambling(Bricks-and-Mortar Casino or Hotel Casino)',
    },
    { name: 'Gambling(Online)', value: 'Gambling(Online)' },
    { name: 'Gaming', value: 'Gaming' },
    { name: 'Gaming(download required)', value: 'Gaming(download required)' },
    {
      name: 'Giveaways/Free Offers/Contest/Free Lotteries/Sweepstakes',
      value: 'Giveaways/Free Offers/Contest/Free Lotteries/Sweepstakes',
    },
    { name: 'Green Card', value: 'Green Card' },
    { name: 'Health', value: 'Health' },
    { name: 'Home', value: 'Home' },
    { name: 'Horoscopes', value: 'Horoscopes' },
    { name: 'Instant Messaging', value: 'Instant Messaging' },
    { name: 'Insurance', value: 'Insurance' },
    { name: 'Internet', value: 'Internet' },
    { name: 'ISP', value: 'ISP' },
    { name: 'Matrimony(India)', value: 'Matrimony(India)' },
    { name: 'Mobile Downloads', value: 'Mobile Downloads' },
    { name: 'Money Meking Offer', value: 'Money Meking Offer' },
    {
      name: 'National Lottery,[Government]Sponsored Lottery',
      value: 'National Lottery,[Government]Sponsored Lottery',
    },
    {
      name: 'Personal Loans and SavingsTransactions',
      value: 'Personal Loans and SavingsTransactions',
    },
    { name: 'Politics', value: 'Politics' },
    { name: 'Public Service', value: 'Public Service' },
    { name: 'Ringtone', value: 'Ringtone' },
    { name: 'Service', value: 'Service' },
    { name: 'Shopping', value: 'Shopping' },
    { name: 'Social Network', value: 'Social Network' },
    {
      name: 'Software (Not Free Download)',
      value: 'Software (Not Free Download)',
    },
    { name: 'Sports', value: 'Sports' },
    { name: 'Survey', value: 'Survey' },
    { name: 'Survey Pop', value: 'Survey Pop' },
    { name: 'Technology', value: 'Technology' },
    { name: 'Tobacco', value: 'Tobacco' },
    { name: 'Travel', value: 'Travel' },
    { name: 'VOIP', value: 'VOIP' },
    { name: 'Weight Loss', value: 'Weight Loss' },
  ]

  // const [managed, setmanaged] = useState(false)
  // useEffect(() => {
  //   if (!auth.auth.user_id) return
  //   const getManaged = () => {
  //     apiService
  //       .data({
  //         path: `luca/accountUser/${auth.auth.user_id}/${auth.account.business_id}`,
  //         method: 'get',
  //       })
  //       .then((value) => {
  //         setmanaged(value)
  //       })
  //   }
  //   getManaged()
  // }, [auth])

  // forms
  const forms = {
    Permission: [
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Tiktok' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '請輸入帳戶名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告帳戶ID',
            label: '廣告帳戶ID',
            placeholder: '請輸入帳戶ID...',
            type: 'text',
            required: true,
          },
          {
            name: '權限人員姓名 - 英文',
            label: '權限人員姓名 - 英文',
            placeholder: '輸入名稱...',
            type: 'text',
            value: '',
            required: true,
            remark: '非潮網人員須於備註告知',
          },
          {
            name: '操作人員信箱 - 編輯',
            label: '操作人員信箱 - 編輯',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '操作人員信箱 - 唯讀',
            label: '操作人員信箱 - 唯讀',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Facebook' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            type: 'select',
            content: managed.filter((m) => m.provider === 'facebook'),
            required: true,
          },
          {
            name: '企業平台編號',
            label: '企業平台編號',
            placeholder: '輸入編號...',
            type: 'tel',
            value: '',
            remark: '非潮網人員請填寫此欄',
          },
          {
            name: '潮網人員信箱',
            label: '潮網人員信箱',
            placeholder: '輸入信箱...',
            type: 'multimail',
            value: '',
            remark:
              '個人信箱僅提供潮網內部人員申請，非潮網人員請填寫企業平台編號',
          },
          {
            name: '帳戶需求權限',
            label: '帳戶需求權限',
            placeholder: '選擇權限...',
            type: 'select',
            content: [
              { name: '管理', value: '管理' },
              { name: '唯讀', value: '唯讀' },
              { name: '編輯', value: '編輯' },
            ],
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Google' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            type: 'select',
            content: managed.filter((m) => m.provider === 'google'),
            required: true,
          },
          {
            name: '權限人員信箱',
            label: '權限人員信箱',
            placeholder: '輸入信箱...',
            type: 'multimail',
            value: '',
            required: true,
          },
          {
            name: '帳戶需求權限',
            label: '帳戶需求權限',
            placeholder: '選擇權限...',
            type: 'select',
            content: [
              { name: '管理', value: '管理' },
              { name: '唯讀', value: '唯讀' },
              { name: '編輯', value: '編輯' },
            ],
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'LinkedIn' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'linkedin'),
            required: true,
          },
          {
            name: '權限人員LinkedIn個人主頁網址',
            label: '權限人員LinkedIn個人主頁網址',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '帳戶需求權限',
            label: '帳戶需求權限',
            placeholder: '選擇權限...',
            type: 'select',
            content: [
              { name: '管理', value: '管理' },
              { name: '唯讀', value: '唯讀' },
              { name: '編輯', value: '編輯' },
            ],
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'YahooDSP' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'yahoodsp'),
            required: true,
          },
          {
            name: '官網連結',
            label: '官網連結',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '權限人員姓名 - 英文',
            label: '權限人員姓名 - 英文',
            placeholder: '輸入名稱...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '權限人員Yahoo信箱',
            label: '權限人員Yahoo信箱',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            remark: '請留意信箱網域有分tw/無tw，否則信箱會收不到驗證信。',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Taboola' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '輸入帳戶名稱...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'taboola'),
            required: true,
          },
          {
            name: '廣告帳戶ID',
            label: '廣告帳戶ID',
            placeholder: '輸入帳戶ID...',
            // type: 'select',
            type: 'text',
            required: true,
          },
          {
            name: '權限人員姓名 - 英文',
            label: '權限人員姓名 - 英文',
            placeholder: '輸入名稱...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '操作人員信箱 - 編輯',
            label: '操作人員信箱 - 編輯',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '操作人員信箱 - 唯讀',
            label: '操作人員信箱 - 唯讀',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'Permission',
        setting: { platform: 'YahooMA' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'yahooma'),
            required: true,
          },
          {
            name: '官網連結',
            label: '官網連結',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '公司名稱 － 工商登記名稱',
            label: '公司名稱 － 工商登記名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '工商登記地址 － 中文',
            label: '工商登記地址 － 中文',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入統一編號...',
            type: 'text',
            required: true,
          },
          {
            name: '權限人員姓名 - 英文',
            label: '權限人員姓名 - 英文',
            placeholder: '輸入名稱...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '權限人員Yahoo信箱',
            label: '權限人員Yahoo信箱',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
            remark: '請留意信箱網域有分tw/無tw，否則信箱會收不到驗證信。',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Line' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'line'),
            required: true,
          },
          {
            name: '權限人員信箱',
            label: '權限人員信箱',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
          },
          {
            name: '帳戶需求權限',
            label: '帳戶需求權限',
            placeholder: '選擇權限...',
            type: 'select',
            content: [
              { name: '管理', value: '管理' },
              { name: '唯讀', value: '唯讀' },
              { name: '編輯', value: '編輯' },
              { name: '技術', value: '技術' },
            ],
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Criteo' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'criteo'),
            required: true,
          },
          {
            name: '權限人員信箱',
            label: '權限人員信箱',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
          },
          {
            name: '帳戶需求權限',
            label: '帳戶需求權限',
            placeholder: '選擇權限...',
            type: 'select',
            content: [
              { name: '管理', value: '管理' },
              { name: '唯讀', value: '唯讀' },
            ],
            value: '',
            required: true,
            remark: '此申請限開立管理/唯讀，如需申請技術權限請於備註中告知',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Permission',
        setting: { platform: 'Dcard' },
        content: [
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'Dcard'),
            required: true,
          },
          {
            name: '權限人員姓名 - 英文',
            label: '權限人員姓名 - 英文',
            placeholder: '輸入姓名...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '權限人員信箱 - 編輯',
            label: '權限人員信箱 - 編輯',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '權限人員信箱 - 唯讀',
            label: '權限人員信箱 - 唯讀',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
    ],
    Recharge: [
      {
        pageLimit: 1,
        type: 'Recharge',
        setting: { platform: 'Tiktok' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '請輸入帳戶名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告帳戶ID',
            label: '廣告帳戶ID',
            placeholder: '請輸入帳戶ID...',
            type: 'text',
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '儲值金額(USD)',
            label: '儲值金額(USD)',
            placeholder: '輸入儲值金額...',
            type: 'tel',
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Recharge',
        setting: { platform: 'Facebook' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            type: 'select',
            content: managed.filter((m) => m.provider === 'facebook'),
            required: true,
          },
          {
            name: '儲值幣別',
            label: '儲值幣別',
            placeholder: '選擇幣別',
            type: 'select',
            content: [
              { name: '台幣', value: '台幣' },
              { name: '美金', value: '美金' },
            ],
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '儲值金額',
            label: '儲值金額',
            placeholder: '輸入儲值金額...',
            type: 'tel',
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Recharge',
        setting: { platform: 'Google' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '選擇帳戶...',
            type: 'select',
            content: managed.filter((m) => m.provider === 'google'),
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '儲值金額(TWD)',
            label: '儲值金額(TWD)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Recharge',
        setting: { platform: 'Twitter' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主Twitter帳號(Handle)',
            label: '廣告主Twitter帳號(Handle)',
            placeholder: '輸入帳號(ex. @wnmedia_tw)...',
            type: 'text',
            required: true,
            constraint: {
              text: '請輸入Handle(ex. @wnmedia_tw)',
              method: (account) => account.startsWith('@'),
            },
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '請填寫帳戶名...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'twitter'),
            required: true,
          },
          {
            name: '廣告主公司英文名稱',
            label: '廣告主公司英文名稱',
            placeholder: '輸入工商登記名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '需儲值的廣告帳戶ID',
            label: '需儲值的廣告帳戶ID',
            placeholder: '輸入ID...',
            type: 'text',
            required: true,
          },
          {
            name: '需求類型',
            label: '需求類型',
            placeholder: '選擇需求類型',
            type: 'select',
            content: [
              {
                name: '儲值新增Funding sources需求',
                value: '儲值新增Funding sources需求',
              },
              {
                name: '展延現有Funding sources需求',
                value: '展延現有Funding sources需求',
              },
            ],
            required: true,
          },
          {
            name: '現有Funding sources結束日期',
            label: '現有Funding sources結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: {
              name: '需求類型',
              value: '展延現有Funding sources需求',
            },
            required: true,
          },
          {
            name: '現有Funding sources預計展延日期',
            label: '現有Funding sources預計展延日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: {
              name: '需求類型',
              value: '展延現有Funding sources需求',
            },
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: {
              name: '需求類型',
              value: '儲值新增Funding sources需求',
            },
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: {
              name: '需求類型',
              value: '儲值新增Funding sources需求',
            },
            required: true,
          },
          {
            name: '儲值金額(美金)',
            label: '儲值金額(美金)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
            dependency: {
              name: '需求類型',
              value: '儲值新增Funding sources需求',
            },
            constraint: {
              text: '須大於等於5000美金。',
              method: (value) => parseInt(value, 10) >= 5000,
            },
            remark:
              '每次儲值額度最低為5000美金，此金額僅為帳戶花費上限，帳單將依您的實際花費請款。',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'Recharge',
        setting: { platform: 'YahooNative' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '請填寫完整帳戶名稱...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'yahooma'),
            required: true,
          },
          {
            name: '官網連結',
            label: '官網連結',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '公司名稱 － 工商登記名稱',
            label: '公司名稱 － 工商登記名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '工商登記地址 － 中文',
            label: '工商登記地址 － 中文',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入統一編號...',
            type: 'text',
            required: true,
            constraint: {
              text: '須填入八碼',
              method: (value) => value.length === 8,
            },
          },
          {
            name: '需求類型',
            label: '需求類型',
            placeholder: '選擇需求類型',
            type: 'select',
            content: [
              { name: '儲值現有委刊單', value: '儲值現有委刊單' },
              { name: '展延現有委刊單', value: '展延現有委刊單' },
              { name: '開立全新委刊單', value: '開立全新委刊單' },
              { name: '儲值+展延現有委刊單', value: '儲值+展延現有委刊單' },
            ],
            required: true,
          },
          {
            name: '現有委刊單結束日期',
            label: '現有委刊單結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            dependency: { name: '需求類型', value: '展延現有委刊單' },
          },
          {
            name: '預計展延日期',
            label: '預計展延日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            remark: '原始起始日最多展延一年',
            dependency: { name: '需求類型', value: '展延現有委刊單' },
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            dependency: { name: '需求類型', value: '開立全新委刊單' },
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '開立全新委刊單' },
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            required: true,
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            remark:
              '單筆最低10,000台幣起，此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款。',
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            remark:
              '單筆最低10,000台幣起，此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款。',
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
            dependency: { name: '需求類型', value: '開立全新委刊單' },
            remark:
              '需達每月50,000台幣，請依活動走期填寫金額，此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款。',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'Recharge',
        setting: { platform: 'Taboola' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '輸入帳戶名稱...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'taboola'),
            required: true,
          },
          {
            name: '廣告帳戶ID',
            label: '廣告帳戶ID',
            placeholder: '輸入帳戶ID...',
            // type: 'select',
            type: 'text',
            required: true,
          },
          {
            name: '需求類型',
            label: '需求類型',
            placeholder: '選擇需求類型',
            type: 'select',
            content: [
              { name: '儲值現有委刊單', value: '儲值現有委刊單' },
              { name: '儲值+展延現有委刊單', value: '儲值+展延現有委刊單' },
            ],
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            remark: '金額無限制',
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            required: true,
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            remark:
              '需達每月60,000台幣，請依活動走期填寫金額，此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'Recharge',
        setting: { platform: 'YahooMA' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告帳戶名稱',
            label: '廣告帳戶名稱',
            placeholder: '請填寫完整帳戶名稱...',
            // type: 'select',
            type: 'text',
            content: managed.filter((m) => m.provider === 'yahooma'),
            required: true,
          },
          {
            name: '官網連結',
            label: '官網連結',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '公司名稱 － 工商登記名稱',
            label: '公司名稱 － 工商登記名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '工商登記地址 － 中文',
            label: '工商登記地址 － 中文',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入統一編號...',
            type: 'text',
            required: true,
            constraint: {
              text: '須填入八碼',
              method: (value) => value.length === 8,
            },
          },
          {
            name: '需求類型',
            label: '需求類型',
            placeholder: '選擇需求類型',
            type: 'select',
            content: [
              { name: '儲值現有委刊單', value: '儲值現有委刊單' },
              { name: '展延現有委刊單', value: '展延現有委刊單' },
              { name: '開立全新委刊單', value: '開立全新委刊單' },
              { name: '儲值+展延現有委刊單', value: '儲值+展延現有委刊單' },
            ],
            required: true,
          },
          {
            name: '現有委刊單結束日期',
            label: '現有委刊單結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '展延現有委刊單' },
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '預計展延日期',
            label: '預計展延日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '展延現有委刊單' },
            required: true,
            remark: '原始起始日最多展延一年',
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '開立全新委刊單' },
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '開立全新委刊單' },
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            required: true,
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            dependency: { name: '需求類型', value: '儲值現有委刊單' },
            required: true,
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            dependency: { name: '需求類型', value: '展延現有委刊單' },
            required: true,
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入儲值金額...',
            type: 'number',
            value: '',
            dependency: { name: '需求類型', value: '儲值+展延現有委刊單' },
            required: true,
          },
          {
            name: '行銷預算(台幣)',
            label: '行銷預算(台幣)',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            dependency: { name: '需求類型', value: '開立全新委刊單' },
            required: true,
            remark:
              '預算需達每月30,000台幣，請依活動預計走期填寫行銷預算金額，此金額僅為帳戶的花費上限，帳單仍會以您的實際花費請款。',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
    ],
    OpenAccount: [
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'Tiktok' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入編號...',
            type: 'text',
            required: true,
          },
          {
            name: '推廣活動名稱',
            label: '推廣活動名稱',
            placeholder: '輸入活動名稱...',
            type: 'text',
          },
          {
            name: '預計投放連結',
            label: '預計投放連結',
            placeholder: '輸入連結...',
            type: 'text',
            required: true,
            remark:
              '推廣內容與連結需要一致(可提供官網/FB/IG等社群＊TikTok帳號不行)',
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '開戶儲值金額(美金)',
            label: '開戶儲值金額(美金)',
            placeholder: '輸入儲值金額...',
            type: 'tel',
            value: '',
            required: true,
            remark:
              '開戶時會進行首筆儲值，該金額需大於3萬台幣。此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款',
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              // { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '附加檔案',
            label: '附加檔案',
            placeholder: '',
            type: 'file',
            required: true,
            remark: '請提供營業登記之含統編的相關政府證明文件',
          },
          {
            name: '操作人員信箱 - 編輯',
            label: '操作人員信箱 - 編輯',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '操作人員信箱 - 唯讀',
            label: '操作人員信箱 - 唯讀',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'Facebook' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司名稱',
            label: '廣告主公司名稱',
            placeholder: '請輸入工商登記名稱..',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主產品/品牌名',
            label: '廣告主產品/品牌名',
            placeholder: '請輸入產品/品牌名稱..',
            type: 'text',
            required: true,
          },
          {
            name: '廣告投放內容',
            label: '廣告投放內容',
            placeholder: '選擇投放內容',
            type: 'select',
            content: [
              { name: '曝光(W)', value: '曝光(W)' },
              { name: '影片觀看(W)', value: '影片觀看(W)' },
              { name: '導流(W)', value: '導流(W)' },
              { name: '活動回覆(W)', value: '活動回覆(W)' },
              { name: '互動(W)', value: '互動(W)' },
              { name: '來店客流量(W)', value: '來店客流量(W)' },
              { name: '增粉(W)', value: '增粉(W)' },
              { name: '名單型廣告(W)', value: '名單型廣告(W)' },
              { name: '造訪IG商業檔案(W)', value: '造訪IG商業檔案(W)' },
              { name: '導流+轉換(P)', value: '導流+轉換(P)' },
              { name: '轉換(P)', value: '轉換(P)' },
              { name: '目錄銷售(P)', value: '目錄銷售(P)' },
            ],
            required: true,
          },
          {
            name: '廣告產品網站',
            label: '廣告產品網站',
            placeholder: '請輸入網址..',
            type: 'text',
            required: true,
          },
          {
            name: '推廣活動名稱',
            label: '推廣活動名稱',
            placeholder: '輸入推廣活動名稱...',
            type: 'text',
          },
          {
            name: '開戶儲值金額 (限台幣)',
            label: '開戶儲值金額 (限台幣)',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            required: true,
            remark: '此申請限開立「台幣帳戶」，需美金帳戶請於備註告知',
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: 'Facebook 粉絲團網址',
            label: 'Facebook 粉絲團網址',
            placeholder: '輸入網址',
            type: 'text',
            required: true,
          },
          {
            name: '粉絲團申請權限',
            label: '粉絲團申請權限',
            placeholder: '選擇權限',
            type: 'select',
            dependency: { name: '帳戶類型', value: '潮網代操' },
            content: [
              { name: '管理', value: '管理' },
              { name: '編輯', value: '編輯' },
              { name: '廣告主', value: '廣告主' },
            ],
            remark: '此為潮網向廣告主Meta粉絲團，申請的使用權限層級。',
            required: true,
          },
          {
            name: '企業平台編號',
            label: '企業平台編號',
            placeholder: '輸入編號',
            type: 'text',
            dependency: { name: '帳戶類型', value: '自行操作' },
            required: true,
            remark: '請複製企業管理平台資料，最上層區塊的企業管理平台編號',
          },
          {
            name: '帳戶時區',
            label: '帳戶時區',
            placeholder: '選擇時區',
            type: 'fixed',
            content: [
              { name: 'GMT+8', value: 'GMT+8' },
              { name: 'GMT+0', value: 'GMT+0' },
            ],
            value: 'GMT+8',
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'Google' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司名稱',
            label: '廣告主公司名稱',
            placeholder: '請輸入工商登記名稱..',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主產品/品牌名',
            label: '廣告主產品/品牌名',
            placeholder: '請輸入產品/品牌名稱..',
            type: 'text',
            value: '',
          },
          {
            name: '廣告產品網站',
            label: '廣告產品網站',
            placeholder: '請輸入網址...',
            type: 'text',
            value: '',
          },
          {
            name: '推廣活動名稱',
            label: '推廣活動名稱',
            placeholder: '輸入推廣活動名稱...',
            type: 'text',
          },
          {
            name: '行銷預算（台幣）',
            label: '行銷預算（台幣）',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '操作人員信箱',
            label: '操作人員信箱',
            placeholder: '請輸入信箱(可複填)...',
            type: 'multimail',
            required: true,
            dependency: { name: '帳戶類型', value: '自行操作' },
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'OpenAccount',
        setting: { platform: 'Twitter' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '工商登記英文名稱',
            label: '工商登記英文名稱',
            placeholder: '輸入英文名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司連絡地址(中)',
            label: '廣告主公司連絡地址(中)',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司連絡地址(英)',
            label: '廣告主公司連絡地址(英)',
            placeholder: '輸入英文地址...',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '產業類別',
            label: '產業類別',
            placeholder: '選擇產業類別',
            type: 'select',
            content: [
              { name: '遊戲應用', value: '遊戲應用' },
              {
                name: '非遊戲應用/科技金融/傳統金融機構/媒體/體育/娛樂',
                value: '非遊戲應用/科技金融/傳統金融機構/媒體/體育/娛樂',
              },
              {
                name: '政府/國企/旅遊/傳統教育機構',
                value: '政府/國企/旅遊/傳統教育機構',
              },
              { name: '科技品牌/電器/汽車', value: '科技品牌/電器/汽車' },
              { name: '電商', value: '電商' },
            ],
            required: true,
          },
          {
            name: '是否為金融相關產業',
            label: '是否為金融相關產業',
            placeholder: '',
            dependency: {
              name: '產業類別',
              value: '非遊戲應用/科技金融/傳統金融機構/媒體/體育/娛樂',
            },
            type: 'check',
            content: [
              {
                name: '廣告主為金融相關產業，已通過原廠預審',
                value: '廣告主為金融相關產業，已通過原廠預審',
              },
              {
                name: '廣告主非金融相關產業',
                value: '廣告主非金融相關產業',
              },
            ],
            remark:
              '金融相關客戶(含NFT加密貨幣等)開戶前須於信件提出客戶工商登記名稱/客戶官網網站/投放地區給解決方案部供原廠預審，待原廠通過後方能申請LUCA開戶',
            required: true,
          },
          {
            name: '是否為金融相關產業',
            label: '是否為金融相關產業',
            placeholder: '',
            dependency: { name: '產業類別', value: '傳統金融機構' },
            type: 'check',
            content: [
              {
                name: '廣告主為金融相關產業，已通過原廠預審',
                value: '廣告主為金融相關產業，已通過原廠預審',
              },
              {
                name: '廣告主非金融相關產業',
                value: '廣告主非金融相關產業',
              },
            ],
            remark:
              '金融相關客戶(含NFT加密貨幣等)開戶前須於信件提出客戶工商登記名稱/客戶官網網站/投放地區給解決方案部供原廠預審，待原廠通過後方能申請LUCA開戶',
            required: true,
          },
          {
            name: '@Handle是否已申請藍(金)勾勾',
            label: '@Handle是否已申請藍(金)勾勾',
            placeholder: '',
            type: 'check',
            content: [
              {
                name: '是',
                value: '是',
              },
              {
                name: '否',
                value: '否',
              },
            ],
            remark:
              '再請留意Handle須通過認證方能申請開戶及投遞廣告，若申請開戶前無通過認證，工作天數將會順延。',
            required: true,
          },
          {
            name: '開戶儲值金額(美金)',
            label: '開戶儲值金額(美金)',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            required: true,
            constraint: {
              text: '金額需大於5000',
              method: (value) => parseInt(value, 10) >= 5000,
            },
            remark:
              '潮網開戶時會進行首筆儲值，該金額需大於5000美金，預算若低於5000美金時請填寫5000，此金額僅為帳戶的花費上限，帳單仍會以您的實際花費請款',
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '廣告帳戶時區',
            label: '廣告帳戶時區',
            placeholder: '輸入時區...',
            type: 'text',
            value: '',
            required: true,
            remark: '一旦設定完成後便無法更改',
          },
          {
            name: '廣告主Twitter帳號(Handle)',
            label: '廣告主Twitter帳號(Handle)',
            placeholder: '輸入帳號(ex. @wnmedia_tw)...',
            type: 'text',
            required: true,
            constraint: {
              text: '必須包含＠字元',
              method: (value) => value.includes('@'),
            },
          },
          {
            name: 'Twitter 帳號網址',
            label: 'Twitter 帳號網址',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '廣告主公司官方網站',
            label: '廣告主公司官方網站',
            placeholder: '輸入連結...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '廣告主產品App連結',
            label: '廣告主產品App連結',
            placeholder: '輸入連結...',
            type: 'text',
            value: '',
          },
          {
            name: '附加檔案',
            label: '附加檔案',
            placeholder: '',
            type: 'file',
            required: true,
            remark:
              '請提供營業登記之含統編的相關政府證明文件，若非公司行號請提供相關政府認證文件',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'OpenAccount',
        setting: { platform: 'LinkedIn' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '工商登記英文名稱',
            label: '工商登記英文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司連絡地址(英文)',
            label: '廣告主公司連絡地址(英文)',
            placeholder: '輸入英文地址...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司電話',
            label: '廣告主公司電話',
            placeholder: '輸入電話...',
            type: 'text',
            required: true,
          },
          {
            name: '五碼郵遞區號',
            label: '五碼郵遞區號',
            placeholder: '輸入郵遞區號...',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '操作人員LinkedIn個人主頁URL',
            label: '操作人員LinkedIn個人主頁URL',
            placeholder: '請輸入URL...',
            type: 'text',
            dependency: { name: '帳戶類型', value: '自行操作' },
          },
          {
            name: '行銷預算（USD）',
            label: '行銷月預算（USD）',
            placeholder: '輸入行銷月預算...',
            type: 'number',
            value: '',
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '廣告投放目標(KPI)',
            label: '廣告投放目標(KPI)',
            placeholder: '輸入目標...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司LinkedIn專頁URL',
            label: '廣告主公司LinkedIn專頁URL',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司官方網站',
            label: '廣告主公司官方網站',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'YahooDSP' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司註冊地址',
            label: '廣告主公司註冊地址',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入編號...',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '行銷預算(台幣) ',
            label: '儲值金額 (台幣) ',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            required: true,
            remark:
              '需達每月50,000台幣，請依活動走期填寫金額，此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款。',
          },
          {
            name: '廣告主公司官方網站',
            label: '廣告主公司官方網站',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '需加入人員的Yahoo信箱 - 管理',
            label: '操作人員Yahoo信箱－管理',
            placeholder: '輸入信箱...',
            type: 'multimail',
            required: true,
            constraint: {
              text: '必須是yahoo信箱',
              method: (email) =>
                email.endsWith('@yahoo.com') || email.endsWith('@yahoo.com.tw'),
            },
            remark:
              'y! Native僅能設定「管理」權限，請輸入以@yahoo.com或@yahoo.com.tw結尾的信箱',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'OpenAccount',
        setting: { platform: 'Taboola' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司工商登記英文名稱',
            label: '廣告主公司工商登記英文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
            remark: '帳戶名稱以英文命名',
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入編號...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司官方網站',
            label: '廣告主公司官方網站',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '產品名稱(中/英)',
            label: '產品名稱(中/英)',
            placeholder: '輸入內容...',
            type: 'text',
            required: true,
            remark: '帳戶名稱以英文命名',
          },
          {
            name: '活動名稱(中/英)',
            label: '活動名稱(中/英)',
            placeholder: '輸入內容...',
            type: 'text',
            required: true,
            remark: '帳戶名稱以英文命名',
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自操', value: '自操' },
              { name: '代操', value: '代操' },
            ],
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
            remark: '走期最多一年',
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            remark: '走期最多一年',
          },
          {
            name: '儲值金額 (台幣) ',
            label: '儲值金額 (台幣) ',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            required: true,
            remark:
              '需達每月60,000台幣，請依活動走期填寫金額，此金額僅為帳戶花費上限，帳單仍會以您的實際花費請款',
          },
          {
            name: '主要廣告格式',
            label: '主要廣告格式',
            placeholder: '選擇廣告格式',
            type: 'select',
            content: [
              { name: 'Native display', value: 'Native display' },
              { name: 'video', value: 'video' },
            ],
            required: true,
          },
          {
            name: 'KPI 活動目標',
            label: 'KPI 活動目標',
            placeholder: '輸入內容...',
            type: 'text',
            required: true,
            remark: '此欄為開放式填寫，在後台可自行調整',
          },
          {
            name: '操作人員信箱 - 編輯',
            label: '操作人員信箱 - 編輯',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '操作人員信箱 - 唯讀',
            label: '操作人員信箱 - 唯讀',
            placeholder: '輸入信箱(可複填)',
            type: 'multimail',
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'YahooMA' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司註冊地址',
            label: '廣告主公司註冊地址',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入編號...',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '客戶產業',
            label: '客戶產業',
            placeholder: '輸入產業...',
            type: 'text',
            required: true,
          },
          {
            name: '活動主打內容',
            label: '活動主打內容',
            placeholder: '輸入內容...',
            type: 'text',
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '儲值金額(台幣)',
            label: '儲值金額(台幣)',
            placeholder: '輸入行銷預算...',
            type: 'number',
            value: '',
            required: true,
            remark: '此僅為帳戶花費額度，帳單仍以您的實際花費請款',
            constraint: {
              text: '潮網開戶首筆儲值，該金額至少為台幣三萬元，預算若低於三萬台幣時請填寫三萬',
              method: (value) => parseInt(value, 10) >= 5000,
            },
          },
          {
            name: '廣告主公司官方網站',
            label: '廣告主公司官方網站',
            placeholder: '輸入網址...',
            type: 'text',
            value: '',
            required: true,
          },
          {
            name: '需加入人員的Yahoo信箱 - 管理',
            label: '操作人員Yahoo信箱－管理',
            placeholder: '輸入信箱...',
            type: 'multimail',
            required: true,
            constraint: {
              text: '必須是yahoo信箱',
              method: (email) =>
                email.endsWith('@yahoo.com') || email.endsWith('@yahoo.com.tw'),
            },
            remark: '請輸入以@yahoo.com或@yahoo.com.tw結尾的信箱',
          },
          {
            name: '需加入人員的Yahoo信箱 - 唯讀',
            label: '業務人員Yahoo信箱－唯讀',
            placeholder: '輸入信箱...',
            type: 'multimail',
            constraint: {
              text: '必須是yahoo信箱',
              method: (email) =>
                email.endsWith('@yahoo.com') || email.endsWith('@yahoo.com.tw'),
            },
            remark: '請輸入以@yahoo.com或@yahoo.com.tw結尾的信箱',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'OpenAccount',
        setting: { platform: 'Line' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司官方網站網址',
            label: '廣告主公司官方網站網址',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告產品工商登記名稱',
            label: '廣告產品工商登記名稱',
            placeholder: '輸入名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '產品網址',
            label: '產品網址',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主客戶 LINE官方帳號基本ID／專屬ID',
            label: '廣告主客戶 LINE官方帳號基本ID／專屬ID',
            placeholder: '輸入名稱 @_________',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '操作人員信箱 - 編輯',
            label: '操作人員信箱 - 編輯',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '自行操作' },
          },
          {
            name: '操作人員信箱 - 唯讀',
            label: '操作人員信箱 - 唯讀',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '自行操作' },
          },
          {
            name: 'AE/AM信箱 - 管理',
            label: '操作人員信箱－管理',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '潮網代操' },
          },
          {
            name: 'AE/AM信箱 - 唯讀',
            label: '業務人員信箱－唯讀',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '潮網代操' },
          },
          {
            name: '行銷預算(TWD) ',
            label: '行銷預算(TWD) ',
            placeholder: '輸入行銷預算...',
            type: 'number',
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '廣告投放內容',
            label: '廣告投放內容',
            placeholder: '輸入內容...',
            type: 'text',
            required: true,
            remark: 'ex:增粉廣告、轉換、曝光',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'TTD' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記名稱',
            label: '廣告主公司中文名稱(正式工商登記)',
            placeholder: '請輸入工商登記名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主品牌名',
            label: '廣告主品牌名',
            placeholder: '輸入品牌名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告產品網站',
            label: '廣告產品網站',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '推廣活動名稱',
            label: '推廣活動名稱',
            placeholder: '輸入活動名稱...',
            type: 'text',
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [{ name: '潮網代操', value: '潮網代操' }],
            required: true,
          },
          {
            name: '行銷預算(美金)',
            label: '行銷預算(美金)',
            placeholder: '輸入行銷預算...',
            type: 'number',
            required: true,
          },
          {
            name: '公司產業類型',
            label: '公司產業類型',
            placeholder: '選擇類型...',
            type: 'select',
            content: ttdCampanyTypes,
            required: true,
          },
          {
            name: '推廣品牌／產品類型',
            label: '推廣品牌／產品類型',
            placeholder: '選擇類型...',
            type: 'select',
            content: ttdTypes,
            required: true,
          },
          {
            name: '廣告主品牌LOGO圖網址',
            label: '廣告主品牌LOGO圖網址',
            placeholder: '輸入網址...',
            type: 'text',
            remark: '使用Google搜尋該品牌時，出現LOGO圖檔的網址連結。',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 2,
        type: 'OpenAccount',
        setting: { platform: 'Criteo' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [
              { name: '自行操作', value: '自行操作' },
              { name: '潮網代操', value: '潮網代操' },
            ],
            required: true,
          },
          {
            name: '操作人員信箱',
            label: '操作人員信箱',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '自行操作' },
            required: true,
          },
          {
            name: '潮網帳戶人員信箱 - 管理',
            label: '操作人員信箱－管理',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '潮網代操' },
          },
          {
            name: '潮網帳戶人員信箱 - 唯讀',
            label: '業務人員信箱－唯讀',
            placeholder: '請輸入信箱...',
            type: 'multimail',
            dependency: { name: '帳戶類型', value: '潮網代操' },
          },
          {
            name: '廣告主公司註冊地址',
            label: '廣告主公司註冊地址',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '郵遞區號',
            label: '郵遞區號',
            placeholder: '輸入郵遞區號...',
            type: 'tel',
            value: '',
            required: true,
            constraint: {
              text: '請填入五碼數字',
              method: (value) => value.length === 5,
            },
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入統一編號...',
            type: 'tel',
            required: true,
            constraint: {
              text: '須填入八碼',
              method: (value) => value.length === 8,
            },
          },
          {
            name: '廣告主公司聯絡地址',
            label: '廣告主公司聯絡地址',
            placeholder: '輸入地址...',
            type: 'text',
            required: true,
          },
          {
            name: '網站/APP網址',
            label: '網站/APP網址',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '投放方式',
            label: '投放方式',
            placeholder: '選擇方式...',
            type: 'checkgroup',
            content: [
              {
                name: 'Commerce growth - acquistion(MCA)',
                value: 'Commerce growth - acquistion(MCA)',
              },
              {
                name: 'Commerce growth - retention(MCR)',
                value: 'Commerce growth - retention(MCR)',
              },
              {
                name: 'Commerce growth - retention retargeting(MRR)',
                value: 'Commerce growth - retention retargeting(MRR)',
              },
            ],
            remark: '若投放再行銷請選MRR，另有拉新流量需求可作全選。',
            required: true,
          },
          {
            name: '投放週期',
            label: '投放週期',
            placeholder: '選擇週期...',
            type: 'select',
            content: [
              { name: '短期、一次性', value: '短期、一次性' },
              { name: '長期投放', value: '長期投放' },
            ],
            required: true,
          },
          {
            name: '預估總預算金額(USD)',
            label: '預估總預算金額(USD)',
            placeholder: '請輸入金額...',
            type: 'number',
            dependency: { name: '投放週期', value: '短期、一次性' },
          },
          {
            name: '預估每月預算金額(USD)',
            label: '預估每月預算金額(USD)',
            placeholder: '請輸入金額...',
            type: 'number',
            dependency: { name: '投放週期', value: '長期投放' },
          },
          {
            name: '客戶官網月獨立訪問用戶數',
            label: '客戶官網月獨立訪問用戶數',
            placeholder: '輸入用戶數...',
            type: 'number',
            remark: '請填寫概數即可，原廠需評估客戶網站流量才可執行開戶。',
            required: true,
          },
          {
            name: '投放國家',
            label: '投放國家',
            placeholder: '輸入國家...',
            type: 'text',
            required: true,
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'Dcard' },
        content: [
          {
            name: '案號 (限潮網人員填寫)',
            label: '案號 (限潮網人員填寫)',
            placeholder: '請輸入案號..',
            type: 'text',
          },
          {
            name: '廣告主公司工商登記中文名稱',
            label: '廣告主公司工商登記中文名稱',
            placeholder: '輸入公司名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '統一編號',
            label: '統一編號',
            placeholder: '輸入統一編號...',
            type: 'tel',
            required: true,
            constraint: {
              text: '須填入八碼',
              method: (value) => value.length === 8,
            },
          },
          {
            name: '廣告主公司官方網站',
            label: '廣告主公司官方網站',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告主公司品牌名稱',
            label: '廣告主公司品牌名稱',
            placeholder: '輸入名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '廣告產品網站',
            label: '廣告產品網站',
            placeholder: '輸入網址...',
            type: 'text',
            required: true,
          },
          {
            name: '推廣活動名稱',
            label: '推廣活動名稱',
            placeholder: '輸入名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '帳戶類型',
            label: '帳戶類型',
            placeholder: '選擇帳戶類型',
            type: 'select',
            content: [{ name: '潮網代操', value: '潮網代操' }],
            required: true,
          },
          {
            name: '每月行銷預算(台幣)',
            label: '每月行銷預算(台幣)',
            placeholder: '輸入行銷預算...',
            type: 'number',
            required: true,
          },
          {
            name: '行銷活動預計走期 － 起始日期',
            label: '行銷活動預計走期 － 起始日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
            constraint: {
              text: '須小於結束日期',
              method: (value, data) => value < data['結束日期'],
            },
          },
          {
            name: '結束日期',
            label: '結束日期',
            placeholder: '輸入日期...',
            type: 'date',
            required: true,
          },
          {
            name: '權限人員信箱 - 編輯',
            label: '權限人員信箱 - 編輯',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '權限人員信箱 - 唯讀',
            label: '權限人員信箱 - 唯讀',
            placeholder: '輸入信箱...',
            type: 'multiemail',
            value: '',
            required: true,
            remark: '請使用公司信箱操作帳戶',
          },
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
      {
        pageLimit: 1,
        type: 'OpenAccount',
        setting: { platform: 'Narwhal' },
        content: [
          {
            name: '備註',
            label: '備註',
            placeholder: '輸入備註...',
            type: 'textarea',
            remark: '如有任何問題可填入此欄位詢問(非必填)',
          },
        ],
      },
    ],
    'apply BM': [
      {
        pageLimit: 1,
        type: 'apply BM',
        setting: { platform: '' },
        content: [
          {
            name: 'ADM名稱',
            label: 'ADM名稱',
            placeholder: '輸入名稱...',
            type: 'text',
            required: true,
          },
          {
            name: '客戶編號',
            label: '客戶編號',
            placeholder: '輸入編號...',
            type: 'text',
          },
        ],
      },
    ],
  }
  return forms[type].find(
    (f) => !f.setting.platform || f.setting.platform === platform
  )
}

const CriteoForm = (content) => {
  const {
    廣告主公司工商登記中文名稱,
    created_on,
    客戶類型,
    廣告主公司註冊地址,
    郵遞區號,
    統一編號,
    廣告主公司聯絡地址,
    投放國家,
    投放週期,
    客戶官網月獨立訪問用戶數,
    投放方式,
    操作人員信箱,
  } = content
  return [
    [
      `Criteo開帳戶資訊提供 - ${廣告主公司工商登記中文名稱} - ${
        created_on.split('T')[0]
      }`,
    ],
    ['客戶資料'],
    ['客戶名稱', `${廣告主公司工商登記中文名稱}`],
    ['客戶類型', `${客戶類型}`],
    ['公司註冊地址', `${廣告主公司註冊地址}`],
    ['郵遞區號', `${郵遞區號}`],
    ['統一編號', `${統一編號}`],
    ['聯絡地址', `${廣告主公司聯絡地址}`],
    [
      '商務聯繫人(姓名/電話/MAIL)',
      'Zoey/+886-2-2366-0699#2614/wavenet.pr@wavenet.com.tw',
    ],
    [
      '技術聯繫人(姓名/MAIL)',
      `${
        操作人員信箱 ||
        `管理:${content['潮網帳戶人員信箱 - 管理']}/唯讀:${content['潮網帳戶人員信箱 - 唯讀']}`
      }`,
    ],
    [
      '財務聯繫人(姓名/電話/MAIL)',
      'Zoey/+886-2-2366-0699#2614/zoey.wang@wavenet.com.tw',
    ],
    ['代理商訊息'],
    ['公司註冊名稱', '潮網科技股份有限公司'],
    ['公司註冊地址', '台北市中正區延平南路189號3樓'],
    ['郵遞區號', '100057'],
    ['統一編號', '25128095'],
    ['公司聯絡地址', '100057 台北市中正區延平南路189號3樓'],
    [
      '商務聯繫人(姓名/電話/MAIL)',
      'Zoey/+886-2-2366-0699#2614/wavenet.pr@wavenet.com.tw',
    ],
    [
      '技術聯繫人(姓名/MAIL)',
      `${
        操作人員信箱 ||
        `管理:${content['潮網帳戶人員信箱 - 管理']}/唯讀:${content['潮網帳戶人員信箱 - 唯讀']}`
      }`,
    ],
    [
      '財務聯繫人(姓名/電話/MAIL)',
      'Zoey/+886-2-2366-0699#2614/zoey.wang@wavenet.com.tw',
    ],
    [
      '接收Invoice邮箱（姓名，电话，邮箱）',
      'Zoey/+886-2-2366-0699#2614/billing@wavenet.com.tw',
    ],
    ['投放設定'],
    ['網站/APP', `${content['網站/APP網址']}`],
    ['投放國家', `${投放國家}`],
    [
      '投放週期與預算',
      `${投放週期}/${
        content['預估總預算金額(USD)'] || content['預估每月預算金額(USD)']
      }`,
    ],
    ['月獨立訪問用戶数Monthly Unique Visitor', `${客戶官網月獨立訪問用戶數}`],
    ['投放方式', `${投放方式}`],
  ]
}

const t = (n) => fields[n] || n

const cpms = [
  {
    label: '解方建議規劃',
    value: '解方建議規劃',
    backgroundColor: '#ffe5a6',
    cpm: null,
  },
  {
    label: '待客戶確認',
    value: '待客戶確認',
    backgroundColor: '#ffe5a6',
    cpm: null,
  },
  { label: 'Display', value: 'Display', backgroundColor: '#ffe5a6', cpm: '80' },
  {
    label: '急湧浪潮',
    value: '急湧浪潮',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '經典特效-RGB',
    value: '經典特效-RGB',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '經典特效-極速快感',
    value: '經典特效-極速快感',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '經典特效-訊號',
    value: '經典特效-訊號',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '動畫窗格',
    value: '動畫窗格',
    backgroundColor: '#c4dbe0',
    cpm: '250',
  },
  {
    label: '靜態展開',
    value: '靜態展開',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '滑動蓋版',
    value: '滑動蓋版',
    backgroundColor: '#c4dbe0',
    cpm: '250',
  },
  {
    label: '滑動蓋版-多圖',
    value: '滑動蓋版-多圖',
    backgroundColor: '#c4dbe0',
    cpm: '250',
  },
  {
    label: '內文開箱',
    value: '內文開箱',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '置底開箱',
    value: '置底開箱',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  { label: '跑馬燈', value: '跑馬燈', backgroundColor: '#c4dbe0', cpm: '227' },
  {
    label: '雙面旋轉',
    value: '雙面旋轉',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '雙面旋轉',
    value: '雙面旋轉',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '3D互動卡牌',
    value: '3D互動卡牌',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '俄羅斯方塊',
    value: '俄羅斯方塊',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '圖片-Sticky',
    value: '圖片-Sticky',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '蓋版輪播-Sticky',
    value: '蓋版輪播-Sticky',
    backgroundColor: '#c4dbe0',
    cpm: '250',
  },
  {
    label: '圖+圖(動態-下推)',
    value: '圖+圖(動態-下推)',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '倒數計時',
    value: '倒數計時',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '倒數計時',
    value: '倒數計時',
    backgroundColor: '#c4dbe0',
    cpm: '227',
  },
  {
    label: '社群貼文',
    value: '社群貼文',
    backgroundColor: '#c4dbe0',
    cpm: '216',
  },
  {
    label: '旋轉木馬',
    value: '旋轉木馬',
    backgroundColor: '#bce1f4',
    cpm: '216',
  },
  {
    label: '上方橫幅-旋轉木馬',
    value: '上方橫幅-旋轉木馬',
    backgroundColor: '#bce1f4',
    cpm: '216',
  },
  {
    label: '立體旋轉卡片',
    value: '立體旋轉卡片',
    backgroundColor: '#bce1f4',
    cpm: '216',
  },
  {
    label: '立體旋轉卡片',
    value: '立體旋轉卡片',
    backgroundColor: '#bce1f4',
    cpm: '227',
  },
  {
    label: '3D旋轉方塊',
    value: '3D旋轉方塊',
    backgroundColor: '#bce1f4',
    cpm: '216',
  },
  {
    label: '旋轉超立方',
    value: '旋轉超立方',
    backgroundColor: '#bce1f4',
    cpm: '216',
  },
  {
    label: '旋轉摩天輪',
    value: '旋轉摩天輪',
    backgroundColor: '#bce1f4',
    cpm: '227',
  },
  {
    label: '置底-立體卡片',
    value: '置底-立體卡片',
    backgroundColor: '#e7cff0',
    cpm: '227',
  },
  {
    label: '目標追擊',
    value: '目標追擊',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  {
    label: '拖曳互動',
    value: '拖曳互動',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  {
    label: '王牌射手',
    value: '王牌射手',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  { label: '刮刮樂', value: '刮刮樂', backgroundColor: '#bce1f4', cpm: '250' },
  {
    label: '趣味投票',
    value: '趣味投票',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  { label: '左右PK', value: '左右PK', backgroundColor: '#bce1f4', cpm: '250' },
  {
    label: '擬真翻頁',
    value: '擬真翻頁',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  {
    label: '互動轉盤',
    value: '互動轉盤',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  {
    label: '互動轉盤',
    value: '互動轉盤',
    backgroundColor: '#bce1f4',
    cpm: '250',
  },
  {
    label: '展示影音-Sticky',
    value: '展示影音-Sticky',
    backgroundColor: '#ffc8ad',
    cpm: '227',
  },
  {
    label: '蓋版影音-Sticky',
    value: '蓋版影音-Sticky',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '影音展開',
    value: '影音展開',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '影音包框',
    value: '影音包框',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '置底橫幅(圖+影)',
    value: '置底橫幅(圖+影)',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '置底影音-立體卡片',
    value: '置底影音-立體卡片',
    backgroundColor: '#e7cff0',
    cpm: '250',
  },
  {
    label: '影音-3D互動卡牌',
    value: '影音-3D互動卡牌',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '影音-3D旋轉方塊',
    value: '影音-3D旋轉方塊',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-動態下推',
    value: '視覺雙享-動態下推',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-立體輪播',
    value: '視覺雙享-立體輪播',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-倒計時',
    value: '視覺雙享-倒計時',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-趣味投票',
    value: '視覺雙享-趣味投票',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-靜態直福',
    value: '視覺雙享-靜態直福',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-靜態橫幅',
    value: '視覺雙享-靜態橫幅',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-圖片轉場',
    value: '視覺雙享-圖片轉場',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-經典輪播',
    value: '視覺雙享-經典輪播',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-左右PK',
    value: '視覺雙享-左右PK',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '視覺雙享-翻頁蓋版',
    value: '視覺雙享-翻頁蓋版',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '影音翻頁蓋版',
    value: '影音翻頁蓋版',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '影音-旋轉木馬',
    value: '影音-旋轉木馬',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '影音-旋轉超立方',
    value: '影音-旋轉超立方',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '行動置底 - 圖中影 + 動畫蓋版',
    value: '行動置底 - 圖中影 + 動畫蓋版',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '行動置底轉盤 - 蓋版影+圖',
    value: '行動置底轉盤 - 蓋版影+圖',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
  {
    label: '行動蓋版 - 影音立體輪播卡片',
    value: '行動蓋版 - 影音立體輪播卡片',
    backgroundColor: '#ffc8ad',
    cpm: '250',
  },
]

export {
  platforms,
  departs,
  cpms,
  getCookie,
  getStaticPath,
  getTimeRanges,
  wordSearch,
  getAvatar,
  getAvatarSm,
  getPermissionType,
  getLanguauage,
  getIcon,
  getOriginIcon,
  getForm,
  getFixedForm,
  CriteoForm,
  t,
}
