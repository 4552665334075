/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faEnvelopeCircleCheck,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import {
  NavBar,
  Footer,
  LoginForm,
  AppWraper,
  ContextProvider,
  ConfirmForm,
  Changelog,
} from './components'
import {
  Welcome,
  Home,
  Info,
  OpenAccount,
  Accounts,
  Billing,
  Users,
  Bms,
  AllUsers,
  Forms,
  UserForms,
  Reports,
  BillRecords,
  Mobile,
} from './pages'
import apiService from './services/apiService'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-multi-email/style.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function App() {
  if (document.documentElement.clientWidth < 577) return <Mobile />
  const [auth, setauth] = useState({
    auth: {},
    account: {},
    accounts: [],
    status: 'unauthed',
  })
  const [requestError, setrequestError] = useState(false)
  const [show, setshow] = useState(false)
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
    hasFooter: true,
    warn: <span className="text-center" />,
  })
  const handleWarnClose = () => {
    if (showWarn.func) showWarn.func()
    setshowWarn({ ...showWarn, func: undefined, show: false })
  }
  const handleLoginShow = () => setshow(true)
  const handleLoginClose = async (value) => {
    if (value) {
      const token =
        value.action === 'login'
          ? await apiService.login(value.data)
          : await apiService.register(value.data)
      if (token && !token.error) {
        // document.cookie = `token=${token}; Domain=${
        //   process.env.REACT_APP_DOMAIN || 'localhost'
        // }`
        document.cookie = `token=${token}; Domain=${window.location.hostname}; Path=/;`
        const user = await apiService.me()
        if (!user.error) {
          const newAuth = {
            auth: { ...user.account },
            status: 'authed',
          }
          if (user.business && user.business.length > 0) {
            newAuth.accounts = [
              { ...user.account, id: '01', type: 'Account' },
            ].concat(user.business.map((b) => ({ ...b, type: 'BM' })))
            newAuth.account =
              newAuth.accounts.find(
                (a) =>
                  a.business_id === parseInt(user.account.setting.defaultBM, 10)
              ) || newAuth.accounts[1]
          }
          setshow(false)
          setauth(newAuth)
          window.location.replace('/')
        } else {
          setshow(false)
          setshowWarn({
            ...showWarn,
            show: true,
            hasFooter: true,
            footer: (
              <Button variant="luca" onClick={() => handleWarnClose(false)}>
                確 定
              </Button>
            ),
            title: '登 入 失 敗',
            titleEng: 'Login Failed',
            warn: (
              <span className="text-center text-nowrap">
                <div className="d-flex mb-3">
                  <FontAwesomeIcon
                    className="mx-auto display-1 text-luca"
                    icon={faTimesCircle}
                  />
                </div>
                <h5 className="lh-lg">
                  登 入 失 敗 !<br />請 確 認 帳 號 和 密 碼 是 否 輸 入 正 確
                  。
                </h5>
              </span>
            ),
          })
        }
      } else {
        document.cookie = `token=; Domain=${window.location.hostname}; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
        setshow(false)
        setshowWarn({
          ...showWarn,
          show: true,
          hasFooter: true,
          footer: (
            <Button
              variant="luca"
              onClick={() => {
                setshowWarn({ ...showWarn, show: false })
              }}
            >
              重 試
            </Button>
          ),
          title: '登 入 失 敗',
          titleEng: 'Login Failed',
          warn: (
            <span className="text-center text-nowrap">
              <div className="d-flex mb-3">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={faTimesCircle}
                />
              </div>
              <h5 className="lh-lg">
                登 入 失 敗 !<br />請 確 認 帳 號 和 密 碼 是 否 輸 入 正 確 。
              </h5>
            </span>
          ),
        })
      }
    }
  }
  const handleLogOut = () => {
    document.cookie = `token=; Domain=${window.location.hostname}; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
    // document.cookie = `token=`
    window.location.replace('/Welcome').then(() => {
      setauth({ status: 'unauthed' })
    })
  }

  // change log
  const [logs, setlogs] = useState(false)
  const [showCl, setshowCl] = useState(false)
  useEffect(() => {
    const getLogs = async () => {
      const res = await apiService.data({
        path: `luca/changelog`,
        method: 'get',
      })
      if (!res.error)
        setlogs(
          res.sort((a, b) => {
            const bArray = b.setting.version.split('.')
            const aArray = a.setting.version.split('.')
            if (bArray[0] === aArray[0]) {
              if (bArray[1] === aArray[1]) return bArray[2] - aArray[2]
              return bArray[1] - aArray[1]
            }
            return bArray[0] - aArray[0]
          })
        )
    }
    getLogs()
  }, [])
  const handleLogsChange = async ({ id, value, action }) => {
    setshowCl(false)
    const func = async () => {
      const res = await apiService.data({
        path: `luca/changelog${id ? `/${id}` : ''}`,
        method: action,
        data: value,
      })
      if (!res.error)
        setlogs(
          action === 'delete'
            ? logs.filter((l) => l.changelog_id !== id)
            : [...logs.filter((l) => l.changelog_id !== id), res].sort(
                (a, b) => {
                  const bArray = b.setting.version.split('.')
                  const aArray = a.setting.version.split('.')
                  if (bArray[0] === aArray[0]) {
                    if (bArray[1] === aArray[1]) return bArray[2] - aArray[2]
                    return bArray[1] - aArray[1]
                  }
                  return bArray[0] - aArray[0]
                }
              )
        )
      setshowCl(true)
    }
    switch (action) {
      case 'post':
        setshowWarn({
          ...showWarn,
          show: true,
          hasFooter: true,
          // footer: (
          //   <Button variant="luca" onClick={() => handleWarnClose(false)}>
          //     確 定
          //   </Button>
          // ),
          title: '系統通知',
          titleEng: '',
          warn: (
            <div className="text-center text-nowrap">
              <span className="d-flex pb-3 pt-5">
                <FontAwesomeIcon
                  style={{ fontSize: '5rem' }}
                  className="text-luca mx-auto"
                  icon={faExclamationCircle}
                />
              </span>
              <h5 className="lh-lg text-lucaDark">確定送出公告嗎？</h5>
            </div>
          ),
          func,
        })
        break
      case 'put':
        setshowWarn({
          ...showWarn,
          show: true,
          hasFooter: true,
          // footer: (
          //   <Button variant="luca" onClick={() => handleWarnClose(false)}>
          //     確 定
          //   </Button>
          // ),
          title: '系統通知',
          titleEng: '',
          warn: (
            <div className="text-center text-nowrap">
              <span className="d-flex pb-3 pt-5">
                <FontAwesomeIcon
                  style={{ fontSize: '5rem' }}
                  className="text-luca mx-auto"
                  icon={faExclamationCircle}
                />
              </span>
              <h5 className="lh-lg text-lucaDark">確定修改公告嗎？</h5>
            </div>
          ),
          func,
        })
        break
      case 'delete':
        setshowWarn({
          ...showWarn,
          show: true,
          hasFooter: true,
          title: '系統通知',
          titleEng: '',
          warn: (
            <div className="text-center text-nowrap">
              <span className="d-flex pb-3 pt-5">
                <FontAwesomeIcon
                  style={{ fontSize: '5rem' }}
                  className="text-luca mx-auto"
                  icon={faExclamationCircle}
                />
              </span>
              <h5 className="lh-lg text-lucaDark">確定刪除公告嗎？</h5>
            </div>
          ),
          func,
        })
        break
      default:
        break
    }
  }

  const handleRequestReset = async (value) => {
    if (value) {
      const res = await apiService.data({
        path: `luca/user/requestReset`,
        method: 'post',
        data: value.data,
      })
      if (res.error) {
        setrequestError({
          content: '無 效 的 信 箱 。',
        })
      }
      if (!res.error) {
        setshow(false)
        setshowWarn({
          ...showWarn,
          show: true,
          hasFooter: true,
          footer: (
            <Button variant="luca" onClick={() => handleWarnClose(false)}>
              確 定
            </Button>
          ),
          title: '重 設 密 碼',
          titleEng: 'Reset Password',
          warn: (
            <span className="text-center text-nowrap">
              <div className="d-flex mb-3">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={faEnvelopeCircleCheck}
                />
              </div>
              <h5 className="lh-lg">
                密 碼 重 設 信 已 寄 出 !<br />請 確 認 信 箱 。
              </h5>
            </span>
          ),
        })
      }
      return !res.error
    }
    return false
  }
  const handleAuthChange = (id, redirect) => {
    if (redirect.includes(':')) {
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.click()
      link.remove()
      setshowCl(true)
    } else {
      setauth({
        ...auth,
        account: auth.accounts.find((a) => a.business_id === id),
      })
      if (redirect && window.location.pathname !== redirect)
        window.location.replace(redirect)
    }
  }
  const updateAuth = (newauth) => {
    setauth({ ...auth, auth: { ...auth.auth, ...newauth } })
  }

  // token check funtion
  const checkToken = async () => {
    const user = await apiService.me()
    if (user && !user.error) {
      const newAuth = {
        auth: { ...user.account },
        account: [],
        status: 'authed',
      }
      if (user.business && user.business.length > 0) {
        newAuth.accounts = user.business.map((b) => ({ ...b, type: 'BM' }))
        newAuth.account =
          newAuth.accounts.find(
            (a) =>
              a.business_id === parseInt(newAuth.auth.setting.defaultBM, 10)
          ) || newAuth.accounts[0]
      }
      setauth(newAuth)
    } else if (
      window.location.pathname !== '/Welcome' &&
      !window.location.pathname.includes('/ResetPassword')
    )
      window.location.replace('/Welcome')
  }

  // content
  const [content, setcontent] = useState({})
  const allowList = content.features
    ? content.features
        .concat(content.forms || [])
        .concat(content.Admin || [])
        .concat(content.superAdmin || [])
        .map((c) => c.link)
    : []
  const allowed = useMemo(
    () =>
      allowList.length !== 0 &&
      (allowList.find(
        (a) => a !== '/' && window.location.pathname.includes(a)
      ) ||
        window.location.pathname === '/'),
    [content, allowList]
  )
  useEffect(() => {
    if (allowList.length !== 0 && !allowed) window.location.replace('/')
  }, [allowed])

  const handleResetPassword = async (password, token) => {
    const res = await apiService.data({
      path: `luca/user/resetPassword`,
      method: 'put',
      data: { password },
      undefined,
      token,
    })
    if (res.error) {
      return 'fail'
    }
    return 'sucess'
  }

  return (
    <ContextProvider
      setting={{
        auth,
        checkToken,
        setcontent,
        content,
      }}
    >
      <div className="App">
        <div
          className="bg-lucaImg d-flex"
          style={{ overflowY: 'auto', flexDirection: 'column' }}
        >
          <Router>
            <NavBar
              setting={{
                auth,
                handleLoginShow,
                handleLogOut,
                handleAuthChange,
                content,
                updateAuth,
              }}
            />
            <AppWraper
              setting={{
                auth,
                checkToken,
                setcontent,
                content,
              }}
            >
              {allowed ? (
                <Routes className="px-0">
                  <Route path="/" element={<Home auth={auth} setting={{}} />} />
                  <Route
                    path="/OpenAccount"
                    element={<OpenAccount auth={auth} action="account" />}
                  />
                  <Route
                    path="/applyAccount"
                    element={<OpenAccount auth={auth} action="account" />}
                  />
                  <Route
                    path="/applyAccount/:platform"
                    element={<OpenAccount auth={auth} action="account" />}
                  />
                  <Route
                    path="/applyBilling"
                    element={<OpenAccount auth={auth} action="billing" />}
                  />
                  <Route
                    path="/applyPermission"
                    element={<OpenAccount auth={auth} action="permission" />}
                  />
                  <Route
                    path="/Accounts"
                    element={<Accounts auth={auth} type="AdsManager" />}
                  />
                  <Route
                    path="/Insights"
                    element={<Accounts auth={auth} type="Insights" />}
                  />
                  <Route path="/Reports" element={<Reports auth={auth} />} />
                  <Route path="/Billing" element={<Billing auth={auth} />} />
                  <Route
                    path="/BillRecords"
                    element={<BillRecords auth={auth} action="records" />}
                  />
                  <Route
                    path="/Bills"
                    element={<BillRecords auth={auth} action="bills" />}
                  />
                  <Route
                    path="/Info"
                    element={
                      <Info
                        auth={auth}
                        checkToken={checkToken}
                        updateAuth={updateAuth}
                        handleLogOut={handleLogOut}
                      />
                    }
                  />
                  <Route path="/Users" element={<Users auth={auth} />} />
                  <Route path="/Bms" element={<Bms auth={auth} />} />
                  <Route path="/AllUsers" element={<AllUsers auth={auth} />} />
                  <Route path="/Forms" element={<Forms auth={auth} />} />
                  <Route
                    path="/Forms/:formId"
                    element={<Forms auth={auth} />}
                  />
                  <Route
                    path="/UserForms"
                    element={<UserForms auth={auth} />}
                  />
                  <Route
                    path="/UserForms/:formId"
                    element={<UserForms auth={auth} />}
                  />
                  {/* <Route path="/Reports" element={<Reports auth={auth} />} /> */}
                  <Route
                    path="/Welcome"
                    element={
                      <Welcome
                        setting={{
                          auth,
                          handleLoginShow,
                          handleResetPassword,
                          handleLogOut,
                        }}
                      />
                    }
                  />
                  <Route
                    path="/*"
                    element={<Home auth={auth} setting={{}} />}
                  />
                </Routes>
              ) : (
                <Routes className="px-0">
                  <Route
                    path="/ResetPassword/:token"
                    element={
                      <Welcome
                        setting={{
                          auth,
                          handleLoginShow,
                          handleResetPassword,
                          handleLogOut,
                        }}
                      />
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      <Welcome
                        setting={{
                          auth,
                          handleLoginShow,
                          handleResetPassword,
                          handleLogOut,
                        }}
                      />
                    }
                  />
                </Routes>
              )}
            </AppWraper>
          </Router>
        </div>

        <LoginForm
          setting={{
            show,
            handleClose: handleLoginClose,
            requestError,
            setrequestError,
            handleRequestReset,
          }}
        />
        <Footer
          style={{ minHeight: '5vh' }}
          setting={{
            logs,
            handleShowCl: () => setshowCl(true),
          }}
        />
        <ConfirmForm
          setting={{
            size: 'md',
            title: '確 定 修 改',
            titleEng: 'Profile Edit Confirm',
            warning: showWarn.warn,
            handleClose: handleWarnClose,
            ...showWarn,
          }}
        />
        {logs && (
          <Changelog
            setting={{
              show: showCl,
              handleClose: () => setshowCl(false),
              logs,
              handleLogsChange,
              setshowWarn,
              isAdmin: auth.account.business_id === 1 && content.superAdmin,
            }}
          />
        )}
      </div>
    </ContextProvider>
  )
}

export default App
