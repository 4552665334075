/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// import numeral from 'numeral'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function AllCostsTable({ setting }) {
  const { costsErp, invoices = [] } = setting

  const refinedHeader = useMemo(
    () =>
      costsErp[0]
        ? costsErp[0]
            .filter((c, index) => !costsErp[1].every((ce) => !ce[index]))
            .map((c) => c[5])
        : [],
    [costsErp]
  )

  const refinedErp = useMemo(
    () =>
      costsErp[1]
        ? costsErp[1].slice(0, 100).reduce(
            (prev, cur) => [
              ...prev,
              cur.reduce(
                (p, c, index) =>
                  c
                    ? {
                        ...p,
                        [costsErp[0][index][5]]: c,
                      }
                    : { ...p },
                {}
              ),
            ],
            []
          )
        : [],
    [costsErp]
  )

  const filteredrecord = useMemo(() => {
    if (!invoices) return {}
    return invoices.reduce((prev, cur) => {
      const curCampaigns = cur.campaigns.data.reduce((p, c) => {
        const project =
          c.campaign_name && c.campaign_name.indexOf('【') !== -1
            ? c.campaign_name.substring(
                c.campaign_name.indexOf('【') + 1,
                c.campaign_name.indexOf('】')
              )
            : ''
        if (project) {
          return {
            ...p,
            [project]: c,
          }
        }
        return p
      }, {})
      return {
        ...prev,
        ...curCampaigns,
      }
    }, {})
  }, [invoices])
  console.log(filteredrecord)

  //   const fields = [
  //     { label: '申請人員' },
  //     { label: '廠商代號' },
  //     { label: '部門' },
  //     { label: '付款條件' },
  //     { label: '付款方式' },
  //     { label: '申購單號' },
  //     { label: '專案代號' },
  //     { label: '備註' },
  //     { label: '狀態' },
  //     { label: '外部資料鍵值' },
  //     { label: '編號' },
  //     { label: '品名/規格' },
  //     { label: '說明' },
  //     { label: '專案代號' },
  //     { label: '專案分攤群組' },
  //     { label: '數量' },
  //     { label: '單位' },
  //     { label: '單價' },
  //     { label: '歸屬部門' },
  //     { label: '分攤群組' },
  //     { label: '財產類別' },
  //     { label: '外加/內含(稅)' },
  //     { label: '稅率' },
  //     { label: '幣別' },
  //     { label: '匯率' },
  //     { label: '原幣金額' },
  //     { label: '原幣稅額' },
  //     { label: '帳上金額' },
  //     { label: '帳上稅額' },
  //     { label: '工單製令' },
  //     { label: '會計科目' },
  //     { label: '資產編號' },
  //     { label: '憑證類別' },
  //     { label: '憑證部門' },
  //     { label: '發票號碼' },
  //     { label: '其他憑證號碼' },
  //     { label: '憑證日期' },
  //     { label: '扣抵種類' },
  //     { label: '外加稅' },
  //     { label: '銷售人' },
  //     { label: '銷售人統一編號' },
  //     { label: '資料所屬月份' },
  //     { label: '進口免稅貨物/勞務' },
  //     { label: '發票格式' },
  //     { label: '載具流水號' },
  //     { label: '扣抵代號' },
  //     { label: '課稅別' },
  //     { label: '備註' },
  //     { label: '業務別' },
  //     { label: '折退原因' },
  //     { label: '租任期別' },
  //     { label: 'WHT收入種類' },
  //     { label: 'WHT稅率(%)' },
  //     { label: 'WHT說明' },
  //     { label: 'WHT稅額' },
  //     { label: '銷售人公司地址' },
  //     { label: '海關代徵營業稅' },
  //     { label: '銷售人名稱' },
  //   ]

  return (
    <Row
      className="text-lucaDark ps-1 overflow-scroll scrollbarShow scrollbarShowBottom"
      style={{
        height: '68vh',
      }}
    >
      <Col>
        <Row
          className="p-0 mx-0 w-100 justift-content-end flex-nowrap"
          style={{
            height: '40px',
          }}
        >
          {/* <Col xs={2} className="py-1 d-flex colBorder App-oneLineEllipsis">
            <span className="d-flex w-100 App-oneLineEllipsis pe-0">
              活動名稱
            </span>
          </Col>
          <Col xs={2} className="py-1 d-flex colBorder App-oneLineEllipsis">
            <span className="d-flex w-100 App-oneLineEllipsis pe-0">
              發票金額
            </span>
          </Col> */}
          {refinedHeader.map((label, i) => (
            <Col
              xs={i === 0 ? 2 : 4}
              key={i}
              className="py-1 d-flex colBorder App-oneLineEllipsis"
            >
              <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                {label}
              </span>
            </Col>
          ))}
        </Row>
        {refinedErp.slice(0, 50).map((rf, i) => (
          <Row
            key={i}
            className="p-0 mx-0 w-100 justift-content-end flex-nowrap"
          >
            {/* {filteredrecord[rf[0]] ? (
              <>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    {filteredrecord[rf[0]].campaign_name}
                  </span>
                </Col>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    {filteredrecord[rf[0]].billed_amount_details.total_amount}
                  </span>
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    發票內容
                  </span>
                </Col>
                <Col
                  xs={2}
                  className="py-1 d-flex colBorder App-oneLineEllipsis"
                >
                  <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                    發票內容
                  </span>
                </Col>
              </>
            )} */}
            {refinedHeader.map((header, j) => (
              <Col
                key={`${i}_${j}`}
                xs={j === 0 ? 2 : 4}
                className="py-1 d-flex colBorder App-oneLineEllipsis"
              >
                {rf[header] ? (
                  <span className="d-flex App-oneLineEllipsis pe-0">
                    {rf[header]}
                  </span>
                ) : (
                  <span className="App-oneLineEllipsis">- -</span>
                )}
              </Col>
            ))}
          </Row>
        ))}
      </Col>
    </Row>
  )
}

AllCostsTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AllCostsTable
