import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

function ShowcaseCard(props) {
  const { setting } = props
  const { title, content, footer, imgSrc, link, method } = setting

  return link ? (
    <Link to={link}>
      <Card className="h-100">
        {imgSrc && <Card.Img src={imgSrc} variant="top w-100" />}
        <Card.Body>
          {title && <Card.Title>{title}</Card.Title>}
          {content && (
            <Card.Text className="pt-3 px-4 text-start lh-md fs-6">
              {content}
            </Card.Text>
          )}
          {footer && (
            <Card.Text className="pt-3 px-4 text-start lh-md fs-6 mt-3 text-center">
              {footer}
            </Card.Text>
          )}
          {method && <Button onClick={method}>申請帳戶</Button>}
        </Card.Body>
      </Card>
    </Link>
  ) : (
    method && (
      <Card className="text-center h-100" onClick={method}>
        <div
          className="w-100 d-flex"
          style={{
            height: '80px',
          }}
        >
          {imgSrc && (
            <Card.Img
              className="m-auto"
              src={imgSrc}
              variant="top"
              style={{
                // height: 'auto',
                width: 'auto',
                opacity: title === 'X' ? '0.7' : '1',
                maxWidth: '120px',
                maxHeight: '80px',
              }}
            />
          )}
        </div>

        <Card.Body>
          {title && (
            <Card.Title
              className="text-nowrap fw-bold"
              style={{ fontSize: '1.35rem' }}
            >
              {title}
            </Card.Title>
          )}
          {content && (
            <Card.Text className="pt-3 px-4 text-start lh-md fs-6">
              {content}
            </Card.Text>
          )}
          {footer && (
            <Card.Text className="px-4 py-5 my-5 text-start lh-md fs-6 text-center text-blue">
              <p className="mt-4">{footer}</p>
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    )
  )
}

ShowcaseCard.propTypes = {
  setting: PropTypes.shape(),
}

ShowcaseCard.defaultProps = {
  setting: {
    title: '跨平台整合',
    content: '在 PUNWAVE 的技術架構下，Nautilus 報表系統承襲跨媒體的優勢。',
    imgSrc:
      'https://www.wavenet.com.tw/wp-content/uploads/2020/01/Nautilus-%E5%84%AA%E5%8B%A2_%E8%B7%A8%E5%B9%B3%E5%8F%B0%E6%95%B4%E5%90%88-min.jpeg',
    link: 'https://nautilus.punwave.com/',
  },
}

export default ShowcaseCard
