import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ListGroup from 'react-bootstrap/ListGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleChevronRight,
  faCircleChevronLeft,
  faHouse,
  faFeather,
  faChartSimple,
  faFolderPlus,
  faPiggyBank,
  faFileWaveform,
  faUserShield,
  faFileShield,
  faFilePen,
  faBook,
  faIdCard,
  faEnvelopeOpenText,
  faHandHoldingDollar,
  faFileInvoiceDollar,
  faMagnifyingGlassDollar,
  faCircleUser,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons'

function SideBar(props) {
  const { setting } = props
  const { auth, content, sidebarStatus, setsidebarStatus } = setting
  const icons = {
    faHouse,
    faFeather,
    faChartSimple,
    faFolderPlus,
    faPiggyBank,
    faFileWaveform,
    faUserShield,
    faFileShield,
    faFilePen,
    faBook,
    faIdCard,
    faSackDollar,
    faEnvelopeOpenText,
    faHandHoldingDollar,
    faFileInvoiceDollar,
    faMagnifyingGlassDollar,
    faCircleUser,
  }

  return sidebarStatus ? (
    <>
      <FontAwesomeIcon
        className="position-absolute h5 text-lucaLink2"
        onClick={() => setsidebarStatus(false)}
        icon={faCircleChevronRight}
        title="收 合 功 能 列"
        style={{
          cursor: 'pointer',
          top: '50%',
          right: '15.3vw',
          zIndex: '800',
        }}
      />
      <Card
        className="text-start border-0 sidebar h-100"
        style={{ overflowY: 'auto' }}
      >
        <Card.Body className="px-2 py-1">
          <ListGroup variant="flush" className="lh-sm">
            {content.features && (
              <>
                {/* <div className="d-flex justify-content-start">
                  <p className="handle-slidebar-title pb-2">常用功能列表</p>
                </div> */}
                <p className="App-oneLineEllipsis handle-slidebar-titleEng pb-2">
                  ❚❚&ensp; Functions
                </p>
                {content.features
                  .filter((c) => c.link !== '/BillRecords')
                  .map((c, i) => (
                    <div key={i} className="pb-1">
                      <ListGroup.Item
                        className={`${
                          c.link === window.location.pathname && 'activebar'
                        }`}
                        active={false}
                        title={c.info}
                      >
                        <Link
                          to={c.link}
                          className="App-oneLineEllipsis App-notextDecoration text-lucaDark fs-7t8"
                        >
                          {c.text}
                          {(c.link === '/Accounts' ||
                            c.link === '/Insights') && (
                            <span style={{ color: '#b42936' }}> βeta</span>
                          )}
                        </Link>
                      </ListGroup.Item>
                    </div>
                  ))}
              </>
            )}
            {content.forms && content.forms.length > 0 && (
              <>
                {/* <p className="handle-slidebar-title pb-2">媒體服務申請</p> */}
                <p className="App-oneLineEllipsis handle-slidebar-titleEng pb-2">
                  ❚❚&ensp; Applications
                </p>
                {content.forms.map((c, i) => (
                  <div key={i} className="pb-1">
                    <ListGroup.Item
                      className={`${
                        c.link === window.location.pathname && 'activebar'
                      }`}
                      active={false}
                      title={c.info}
                    >
                      <Link
                        to={c.link}
                        className="App-oneLineEllipsis App-notextDecoration text-lucaDark fs-7t8"
                      >
                        {c.text}
                      </Link>
                    </ListGroup.Item>
                  </div>
                ))}
              </>
            )}

            {auth.account &&
              auth.account.type === 'BM' &&
              content.Admin &&
              content.Admin.length > 0 && (
                <>
                  {/* <p className="handle-slidebar-title pb-2">管理員設定</p> */}
                  <p className="App-oneLineEllipsis handle-slidebar-titleEng pb-2">
                    ❚❚&ensp; Administration
                  </p>
                  {content.Admin.filter((c) => c.link !== '/Bills').map(
                    (c, i) => (
                      <div key={i} className="pb-1">
                        <ListGroup.Item
                          className={`${
                            c.link === window.location.pathname && 'activebar'
                          }`}
                          active={false}
                          title={c.info}
                        >
                          <Link
                            to={c.link}
                            className="App-oneLineEllipsis App-notextDecoration text-lucaDark fs-7t8"
                          >
                            {c.text}
                          </Link>
                        </ListGroup.Item>
                      </div>
                    )
                  )}
                </>
              )}

            {auth.account && content.superAdmin && (
              <>
                {/* <p className="handle-slidebar-title pb-1">LUCA管理員專區</p> */}
                <p className="App-oneLineEllipsis handle-slidebar-titleEng pb-2">
                  ❚❚&ensp; LUCA Managers
                </p>
                {content.superAdmin
                  .filter((c) => c.link !== '/Bills')
                  .map((c, i) => (
                    <div key={i} className="pb-1">
                      <ListGroup.Item
                        className={`${
                          c.link === window.location.pathname && 'activebar'
                        }`}
                        active={false}
                        title={c.info}
                      >
                        <Link
                          to={c.link}
                          className="App-oneLineEllipsis App-notextDecoration text-lucaDark fs-7t8"
                        >
                          {c.text}
                        </Link>
                      </ListGroup.Item>
                    </div>
                  ))}
              </>
            )}
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  ) : (
    <>
      <FontAwesomeIcon
        className="position-absolute h5 text-lucaLink2"
        onClick={() => setsidebarStatus(true)}
        icon={faCircleChevronLeft}
        title="展 開 功 能 列"
        style={{
          cursor: 'pointer',
          top: '50%',
          right: '5.6vw',
          zIndex: '800',
        }}
      />
      <Card
        className="text-start border-0 sidebar h-100"
        style={{ overflowY: 'auto' }}
      >
        <Card.Body>
          {content.features
            .filter((c) => c.link !== '/BillRecords')
            .map((c, i) => (
              <div key={i} className="pb-1">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 400, hide: 400 }}
                  overlay={
                    <Tooltip
                      className="sidebarTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      {c.text}
                    </Tooltip>
                  }
                >
                  <Link
                    to={c.link}
                    className="d-flex App-notextDecoration text-light h4 text-center"
                  >
                    <FontAwesomeIcon
                      className={`mx-auto text-center py-2 ${
                        c.link === window.location.pathname && 'activebar_sm'
                      }`}
                      icon={icons[c.icon]}
                    />
                  </Link>
                </OverlayTrigger>
              </div>
            ))}

          {content.forms.length > 0 && (
            <>
              <hr />
              {content.forms.map((c, i) => (
                <div key={i} className="pb-1">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 400, hide: 400 }}
                    overlay={
                      <Tooltip
                        className="sidebarTip"
                        style={{
                          zIndex: '9999',
                        }}
                      >
                        {c.text}
                      </Tooltip>
                    }
                  >
                    <Link
                      to={c.link}
                      className="d-flex App-notextDecoration text-light h4 text-center"
                    >
                      <FontAwesomeIcon
                        className={`mx-auto text-center py-2 ${
                          c.link === window.location.pathname && 'activebar_sm'
                        }`}
                        icon={icons[c.icon]}
                      />
                    </Link>
                  </OverlayTrigger>
                </div>
              ))}
            </>
          )}

          {auth.account &&
            auth.account.type === 'BM' &&
            content.Admin &&
            content.Admin.length > 0 && (
              <>
                <hr />
                {content.Admin.filter((c) => c.link !== '/Bills').map(
                  (c, i) => (
                    <div key={i} className="pb-1">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 400, hide: 400 }}
                        overlay={
                          <Tooltip
                            className="sidebarTip"
                            style={{
                              zIndex: '9999',
                            }}
                          >
                            {c.text}
                          </Tooltip>
                        }
                      >
                        <Link
                          to={c.link}
                          className="d-flex App-notextDecoration text-light h4 text-center"
                        >
                          <FontAwesomeIcon
                            className={`mx-auto text-center py-2 ${
                              c.link === window.location.pathname &&
                              'activebar_sm'
                            }`}
                            icon={icons[c.icon]}
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>
                  )
                )}
              </>
            )}

          {auth.account && content.superAdmin && (
            <>
              <hr />
              {content.superAdmin
                .filter((c) => c.link !== '/Bills')
                .map((c, i) => (
                  <div key={i} className="pb-1">
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 400, hide: 400 }}
                      overlay={
                        <Tooltip
                          className="sidebarTip"
                          style={{
                            zIndex: '9999',
                          }}
                        >
                          {c.text}
                        </Tooltip>
                      }
                    >
                      <Link
                        to={c.link}
                        className="d-flex App-notextDecoration text-light h4 text-center"
                      >
                        <FontAwesomeIcon
                          className={`mx-auto text-center py-2 ${
                            c.link === window.location.pathname &&
                            'activebar_sm'
                          }`}
                          icon={icons[c.icon]}
                        />
                      </Link>
                    </OverlayTrigger>
                  </div>
                ))}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

SideBar.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SideBar
